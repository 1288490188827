import _ from "underscore";
import UA from "../lib/UA";
import IsPassiveSupport from "../lib/IsPassiveSupport";

export default class Header {
  constructor() {
    this.container = document.getElementsByClassName("js-header")[0];
    this.fixedElm = this.container.getElementsByClassName('js-headerFixed')[0];
    this.searchElm = this.container.getElementsByClassName("js-headerSearch")[0];
    this.categoryElm = this.container.getElementsByClassName("js-headerCategory")[0];
    this.breakPoint = 768;
    this.scrollPos = 0;
    this.scrollBasePos = 0; // 上下スクロールが切り替わった時の位置
    this.scrollDirection = "";
    this.isShowHeaderSp = true;
    this.isShowSearchSp = true;
    this.timeout;
    this.lockFlg = false;
    this.ua = new UA();
    this.pointLoaded = false;
    this.init();
  }

  init() {
    this.setSuggestHeight();
    this.bindEvents();
  }

  bindEvents() {
    const self = this;
    const isPassive = new IsPassiveSupport();
    const touchEvent = 'ontouchstart' in window ? 'touchstart' : 'click';

    // PCロゴメニュー
    this.debouncedOnLogoClick = _.debounce(btn => {
      if (btn.classList.contains("-active")) {
        this.closeLogoMenu();
      } else {
        this.openLogoMenu();
        if (touchEvent == "click") {
          this.closeAccountMenu();
        }
      }
    }, 0);
    const logoContainer = document.getElementsByClassName("js-headerLogo")[0];
    if (logoContainer) {
      const logoBtn = logoContainer.getElementsByClassName("js-headerLogoBtn")[0];

      if (logoBtn) {
        logoBtn.addEventListener("click", e => {
          if (window.innerWidth >= this.breakPoint) {
            e.preventDefault();
            e.stopPropagation();
            if (this.lockFlg) return false;
            this.lockFlg = true;
            this.debouncedOnLogoClick(logoBtn);
          }
        });
      }
    }

    // PCアカウントメニュー
    this.debouncedOnAccountClick = _.debounce(btn => {
      if (btn.classList.contains("-active")) {
        this.closeAccountMenu();
      } else {
        this.openAccountMenu();
        // load point
        if (!this.pointLoaded) {
          "loadPoint" in window && window.loadPoint();
          this.pointLoaded = true;
        }
        if (touchEvent == "click") {
          this.closeLogoMenu();
        }
      }
    }, 0);
    const accountContainer = document.getElementsByClassName("js-headerAccount")[0];
    if (logoContainer) {
      const accountBtn = accountContainer.getElementsByClassName("js-headerAccountBtn")[0];

      if (accountBtn) {
        accountBtn.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          if (this.lockFlg) return false;
          this.lockFlg = true;
          this.debouncedOnAccountClick(accountBtn);
        });
      }
    }

    // 範囲外を選択
    document.querySelector("body").addEventListener(touchEvent, e => {
      if (!e.target.closest(".js-headerLogo")) {
        this.closeLogoMenu();
      }
      if (!e.target.closest(".js-headerAccount")) {
        this.closeAccountMenu();
      }
    });

    // resize event
    const debouncedOnResize = _.debounce(() => {
      this.resize();
      this.setSuggestHeight();
    }, 100);
    window.addEventListener("resize", () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);

    // pc scroll event
    this.onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
      }
      this.timeout = requestAnimationFrame(() => {
        self.scroll();
      });
    }

    document.addEventListener(
      "scroll",
      () => {
        this.onScroll();
      },
      isPassive.check() ? { passive: true } : false
    );
    this.onScroll();
  }

  openLogoMenu() {
    const container = document.getElementsByClassName("js-headerLogo")[0];

    if (container) {
      const btn = container.getElementsByClassName("js-headerLogoBtn")[0];
      const content = container.getElementsByClassName("js-headerLogoContent")[0];

      if (btn && content) {
        content.classList.add("-visible");
        _.delay(() => {
          btn.classList.add("-active");
          content.classList.add("-show");
          this.lockFlg = false;
        }, 40);
      }
    }
  }

  closeLogoMenu() {
    const container = document.getElementsByClassName("js-headerLogo")[0];

    if (container) {
      const btn = container.getElementsByClassName("js-headerLogoBtn")[0];
      const content = container.getElementsByClassName("js-headerLogoContent")[0];

      if (btn && content) {
        btn.classList.remove("-active");
        content.classList.remove("-show");
        _.delay(() => {
          content.classList.remove("-visible");
          this.lockFlg = false;
        }, 480);
      }
    }
  }

  openAccountMenu() {
    const container = document.getElementsByClassName("js-headerAccount")[0];

    if (container) {
      const btn = container.getElementsByClassName("js-headerAccountBtn")[0];
      const content = container.getElementsByClassName("js-headerAccountContent")[0];

      if (btn && content) {
        content.classList.add("-visible");
        _.delay(() => {
          btn.classList.add("-active");
          content.classList.add("-show");
          this.lockFlg = false;
        }, 40);
      }
    }
  }

  closeAccountMenu() {
    const container = document.getElementsByClassName("js-headerAccount")[0];

    if (container) {
      const btn = container.getElementsByClassName("js-headerAccountBtn")[0];
      const content = container.getElementsByClassName("js-headerAccountContent")[0];

      if (btn && content) {
        btn.classList.remove("-active");
        content.classList.remove("-show");
        _.delay(() => {
          content.classList.remove("-visible");
          this.lockFlg = false;
        }, 480);
      }
    }
  }

  resize() {
    if (window.innerWidth < this.breakPoint) {
      // sp
      if (this.fixedElm) {
        this.fixedElm.setAttribute('style', '');
      }
    }
  }

  scroll() {
    if (document.body.classList.contains("-modalOpen")) {
      return false;
    }

    let openSuggestFlg = false;

    if (window.innerWidth < this.breakPoint) {
      if (document.body.classList.contains("-spMenuOpen")) {
        return false;
      }
    } else {
      if (
        this.container.classList.contains("-openHistory") ||
        this.container.classList.contains("-openSuggest")
      ) {
        // 検索履歴・サジェスト表示時はヘッダーを固定
        openSuggestFlg = true;
      }
    }

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollX = window.scrollX || window.pageXOffset;

    if (this.scrollDirection == "") this.scrollBasePos = scrollTop;

    const diffPos = this.scrollBasePos - scrollTop;

    if (!openSuggestFlg) {
      if (scrollTop > this.scrollPos) {
        // 下にスクロール
        if (this.scrollDirection != "bottom") {
          this.scrollBasePos = scrollTop;
        }
        this.scrollDirection = "bottom";

        let pos1 = -100;
        let pos2 = -250;

        if (!this.searchElm) {
          pos2 = pos1;
        }

        if (diffPos < pos1) {
          if (this.searchElm) {
            this.searchElm.classList.add("-hide");
          }
          if (this.categoryElm) {
            this.categoryElm.classList.add("-hide");
            document.body.classList.add("-hideHeaderCategory");
          }
        }
        if (diffPos < pos2) {
          if (this.fixedElm) {
            this.fixedElm.classList.remove("-hide");
            this.fixedElm.classList.add("-hideAll");
            document.body.classList.remove("-hideHeaderCategory");
            document.body.classList.add("-hideHeader");
          }
        }
      } else {
        // 上にスクロール
        if (this.scrollDirection != "top") {
          this.scrollBasePos = scrollTop;
        }
        this.scrollDirection = "top";

        let pos1 = 100;

        if (diffPos > pos1) {
          if (this.searchElm) {
            this.searchElm.classList.remove("-hide");
          }
          if (this.categoryElm) {
            this.categoryElm.classList.remove("-hide");
            document.body.classList.remove("-hideHeaderCategory");
          }
          if (this.fixedElm) {
            this.fixedElm.classList.remove("-hideAll");
            this.fixedElm.classList.remove("-hide");
            document.body.classList.remove("-hideHeaderCategory");
            document.body.classList.remove("-hideHeader");
          }
        }

        if (scrollTop < 120) {
          if (this.searchElm) {
            this.searchElm.classList.remove("-hide");
          }
          if (this.categoryElm) {
            this.categoryElm.classList.remove("-hide");
            document.body.classList.remove("-hideHeaderCategory");
          }
          if (this.fixedElm) {
            this.fixedElm.classList.remove("-hideAll");
            this.fixedElm.classList.remove("-hide");
            document.body.classList.remove("-hideHeaderCategory");
            document.body.classList.remove("-hideHeader");
          }
        }
      }
    }

    if (this.fixedElm) {
      if (window.innerWidth >= this.breakPoint) {
        this.fixedElm.setAttribute('style', 'transform: translateX(-' + scrollX + 'px)');
      }
    }

    if (scrollTop < 120) {
      if (this.searchElm) {
        this.searchElm.classList.remove("-hide");
      }
      if (this.categoryElm) {
        this.categoryElm.classList.remove("-hide");
        document.body.classList.remove("-hideHeaderCategory");
      }
      if (this.fixedElm) {
        this.fixedElm.classList.remove("-hideAll");
        this.fixedElm.classList.remove("-hide");
        document.body.classList.remove("-hideHeaderCategory");
        document.body.classList.remove("-hideHeader");
      }
    }

    this.scrollPos = scrollTop;
  }

  setSuggestHeight() {
    const searchHistory = this.container.getElementsByClassName("js-searchHistory")[0];
    const searchSuggest = this.container.getElementsByClassName("js-searchSuggest")[0];

    if (window.innerWidth < this.breakPoint) {
      if (searchHistory) {
        const rect = searchHistory.getBoundingClientRect();
        const height = window.innerHeight - rect.top;
        searchHistory.style.height = height + "px";
      }
      if (searchSuggest) {
        const rect = searchSuggest.getBoundingClientRect();
        const height = window.innerHeight - rect.top;
        searchSuggest.style.height = height + "px";
      }
    } else {
      if (searchHistory) {
        searchHistory.style.height = "";
        const rect = searchHistory.getBoundingClientRect();
        const height = window.innerHeight - rect.top - 16;
        if (window.innerHeight < (rect.top + rect.height + 16)) {
          searchHistory.style.height = height + "px";
        }
      }
      if (searchSuggest) {
        searchSuggest.style.height = "";
        const rect = searchSuggest.getBoundingClientRect();
        const height = window.innerHeight - rect.top -16;
        if (window.innerHeight < (rect.top + rect.height + 16)) {
          searchSuggest.style.height = height + "px";
        }
      }
    }
  }
}
